<template>
  <main>
    <ul class="car-ul">
      <ui-checkbox-group
              @on-change="changeBoxGroup"
              v-model:value.sync="checked1"
              v-if="list && list.length > 0"
      >
        <li class="car-li" v-for="(item, index) in list" :key="index">
          <div class="product-wapper">
            <ui-checkbox
                    :label="item.pidAndLotId"
                    size="large"
            >
              <span style="font-size: 0">{{ item.pidAndLotId }}</span>
            </ui-checkbox>
            <div class="product-pic">
              <router-link
                      :to="{
                  name:
                    item.tid == 0
                      ? 'productdetail'
                      : item.tid == 1 || item.tid == 2
                      ? 'bnccdetail'
                      : '',
                  params: { id: item.pid, classId: item.classId },
                }"
              >
                <img :src="item.pic" alt="" />
              </router-link>
            </div>
            <div class="product-info">
              <router-link
                      :to="{
                  name:
                    item.tid == 0
                      ? 'productdetail'
                      : item.tid == 1 || item.tid == 2
                      ? 'bnccdetail'
                      : '',
                  params: { id: item.pid, classId: item.classId },
                }"
                      class="product-name"
              >
                <img v-if="item.cartType == 1" src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/56a04e4bb57ef6fd80c7e15f845c2bde.png" />
                {{ item.title }}
              </router-link>
              <div class="product-no" v-if="item.tid ==0">
                {{ item.itemNo }}
              </div>
              <div class="product-no" v-else> BNCC{{item.pid}}</div>
              <div class="product-no">{{ item.spec }}<span v-if="item.spec&&item.nongdu">,</span> {{item.nongdu}}</div>
              <div class="poroduct-num" v-if="item.cartType == 1">
                <span class="sub"></span>
                <div class="input">
                  <input
                          type="number"
                          min="1"
                          name="num"
                          step="1"
                          v-model.number="item.qty"
                          readonly
                  />
                </div>
                <span class="add"></span>
                <span class="single-price" v-if="biaowuPriceOpen && biaowuPriceOpen == '1' && item.price>=0">￥{{Number(item.price).toFixed(2)}}</span>
                <span class="single-price" v-else>￥{{Number(item.retailPrice).toFixed(2)}}</span>
              </div>
              <div class="poroduct-num" v-else>
                <span class="sub" @click="isBtnDisabled &&onSub(item.pidAndLotId, index)"></span>
                <div class="input">
                  <input
                      type="number"
                      min="1"
                      name="num"
                      step="1"
                      @change=" changeEdit(item.pidAndLotId, item.qty, index)"
                      v-model.number="item.qty"
                  />
                </div>
                <span class="add" @click="isBtnDisabled &&onAdd(item.pidAndLotId, index)"></span>
                <span class="single-price" v-if="biaowuPriceOpen && biaowuPriceOpen == '1' && item.price>=0">￥{{Number(item.price).toFixed(2)}}</span>
                <span class="single-price" v-else>￥{{Number(item.retailPrice).toFixed(2)}}</span>
              </div>
            </div>
          </div>
        </li>

        <li class="car-li">
          <div class="product-wapper">
            <ui-checkbox
                    label="22"
                    size="large"
                    style="opacity: 0;"
            >
              <span style="font-size: 0"></span>
            </ui-checkbox>
            <div class="product-pic">
              <img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/571537662b085738c3a7e783bb0dd51e.png" alt="" />
            </div>
            <div class="product-info">
              <div class="product-name">快递+包装费用</div>
              <div class="product-no">金额满{{freeShippingLimit}}包邮</div>
              <div class="product-no" style="opacity: 0;">500ml</div>
              <div class="poroduct-num">
                <span class="sub"></span>
                <div class="input">
                  <input
                          type="number"
                          min="1"
                          name="num"
                          step="1"
                          disabled
                          v-model="delivery"
                  />
                </div>
                <span class="add"></span>
                <span class="single-price" v-if="biaowuPriceOpen && biaowuPriceOpen == '1' && totalPrice < freeShippingLimit">￥{{ Number(farePrice).toFixed(2)}}</span>
                <span class="single-price" v-if="biaowuPriceOpen && biaowuPriceOpen == '1' && totalPrice >= freeShippingLimit">￥0.00</span>
              </div>
            </div>
          </div>
        </li>
<!--        <li class="car-li" v-if="totalPrice < freeShippingLimit && couponFreeMailSize >0" style="display: block">-->
<!--          <img style="width: 24px;vertical-align: middle ;margin-left: 108px;margin-right: 5px" src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/317e43bea5d431da13495eeeb89788e6.png" alt="">-->
<!--          <span class="CouponInfo" style="vertical-align: middle">免邮券{{couponFreeMailSize}}张可用，请联系客服使用</span>-->
<!--        </li>-->
        <li class="car-li" v-if="myUserlogin == 1">
          <div class="product-wapper">
            <ui-checkbox
                label="22"
                size="large"
                style="opacity: 0;"
            >
              <span style="font-size: 0"></span>
            </ui-checkbox>

            <div class="product-info">
              <div class="product-name" style="width:100%;font-weight: bold">
<!--                <span style="display: block;line-height: 40px">（检测您为新用户，通过审核后可享受半价）</span>-->
<!--                <span>￥{{ Number(totalPrice*0.5).toFixed(2) }}-->
                <span v-if="biaowuPriceOpen && biaowuPriceOpen == '1'">原价：￥{{ Number(totalPrice).toFixed(2) }}</span>
<!--                  </span>-->
<!--                <span class="single-price" v-if="biaowuPriceOpen && biaowuPriceOpen == '1' && totalPrice >= freeShippingLimit">￥0.00</span>-->
              </div>
            </div>
          </div>
        </li>
        <li class="car-li" v-show="isCoupon" style="display: block">
          <img style="width: 24px;vertical-align: middle ;margin-left: 55px;margin-right: 5px" src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/317e43bea5d431da13495eeeb89788e6.png" alt="">
          <span class="CouponInfo" style="vertical-align: middle">优惠券{{couponCount}}张可用,可优惠{{couponPrice}}元</span>
          <span class="changeCoupon" @click="getModal()">查看</span>
        </li>
      </ui-checkbox-group>
    </ul>
    <div class="dashboard">
      <div class="dashboard-checkbox">
        <ui-checkbox
                label="全选"
                v-model="checked"
                @on-change="changeBox"
                size="large"
        >
        </ui-checkbox>

        <div class="del" @click="onDelete">删除</div>
      </div>

      <div @click="onSubmit" class="pay" v-if="totalPrice<freeShippingLimit &&totalPrice>=0 && count >0">
        <template v-if="biaowuPriceOpen && biaowuPriceOpen == '1'">
          提交( <span>￥{{ Number(totalPrice+farePrice).toFixed(2) }}</span> )
        </template>
        <template v-else><span style="padding: 0 10px;">下一步</span></template>
      </div>

      <div @click="onSubmit" class="pay" v-else>
        <!-- 提交(<span>{{ count }}</span>) -->
        <template v-if="biaowuPriceOpen && biaowuPriceOpen == '1'">
          提交( <span>￥{{ Number(totalPrice).toFixed(2) }}</span> )
        </template>
        <template v-else><span style="padding: 0 10px;">下一步</span></template>
      </div>
    </div>
  </main>
  <loading :options="loadOption" v-if="loading"></loading>
  <empty text="购物车" :showLive="false" v-if="list && !list.length"></empty>


  <!--        弹窗-->
  <div class="modal1" id="modal1" v-if="showModal">
    <div class="modal-backdrop"></div>
    <div class="modal-body">
      <form id="couponGiveForm">
        <label v-for="(item,index) in couponList " :key="index">
          <img style="width: 24px;position: relative;bottom: 3px    " src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/317e43bea5d431da13495eeeb89788e6.png" alt="">
          <!--          <input style="margin-right: 0" type="radio" name="CouponId" :value="item.amount" @click="getCouponId(item.id)">-->
          <span style="vertical-align: middle;">{{item.amount}}元优惠券</span>
          <!--          <span>满{{item.usePrice}}可用</span>-->
          <span style="vertical-align: middle;"> 截止{{item.expiredTime.substr(0,10)}}</span>
        </label>
      </form>
      <div class="coupon-button">
        <span class="coupon_btn" @click="closeModal()">确定</span>
        <!--        <span class="coupon_btn" style="background: #999" @click="closeModal()">取消</span>-->
      </div>
    </div>
  </div>

</template>

<script lang="ts">
import { computed, defineComponent, reactive, ref, watch, inject,getCurrentInstance } from "vue";
  import UiCheckboxGroup from "@/components/UI/CheckboxGroup";
  import UiCheckbox from "@/components/UI/Checkbox";

  import axios from "@/api/axios";
  import api from "@/api/axios";
  import Toast from "@/components/UI/Toast";
  import useShopCar from "@/hooks/useShopCar";
  import Loading from "@/components/UI/Loading/src/Loading.vue";
  import qs from "qs";
  import Modal from "@/components/UI/Modal";
  import router from "@/router";
  import Empty from "@/components/common/Empty.vue";
  import { getCartInfo } from "@/api/useChangeShopCar";
  import OrderRedPackage from "@/components/common/OrderRedPackage.vue"
  export default defineComponent({
    name: "ShopCar",
    components: {
      UiCheckbox,
      UiCheckboxGroup,
      Loading,
      Empty,
      // OrderRedPackage
    },
    data() {
      return {
        CouponUse:true,
        showModal: false,
        // 优惠券
        coupon:'',
        //展示金额
        couponPriceShow:0,
        //优惠券个数
        couponCount:0,
        //真实金额
        couponPrice:0,
        couponList:[],
        isCoupon:false,
        couponId:0,
        // couponFreeMailSize:0,
      }
    },
    methods:{
      getModal(){
        this.showModal=true
      },
      closeModal(){
        this.showModal=false
        // this.couponId= this.couponList[0].id
      },
      //选优惠券
      getCouponId(id: any){
        this.couponId=id
      },
      //确定选中 并更新外部优惠券/总价信息
      changeCoupon(){
        // let indexCoupon=0
        // this.couponList.map((item: any,index: any)=>{
        //   if(item.id===this.couponId){
        //     indexCoupon=index
        //   }
        // });
        // this.totalPrice+=this.couponPrice
        // this.couponPrice=this.couponList[indexCoupon].amount
        // this.couponPriceShow=this.couponList[indexCoupon].amount
        // this.totalPrice-=this.couponPrice
        // console.log(this.totalPrice)
        // Toast({
        //   type: "success",
        //   title: '更换成功',
        // });
        this.showModal=false
      }
    },
    setup() {
      const checkFlag = ref(true);
      const visiblePic = ref(false)
      const passCode = ref<string>("");
      // const showRedPackage = ref(false)
      const biaowuPriceOpen = ref()
      const delivery = ref(1)
      const list = ref<[] | null>(null);
      const checked = ref(true);
      const checked1 = reactive<any>([]);
      const cartTypeVal1 = ref(0);
      const loading = ref(true);
      const totalPrice = ref(0)
      //包邮限制金额
      const freeShippingLimit = ref(0)
      const farePrice = ref(0)
      const userInfo = inject("userInfo") as any;
      //获取vue data
      const getThis = getCurrentInstance() as any;
      const isBtnDisabled=ref(true);
      //观察选择
      const changeBox = (val: any) => {
        let allPrice = 0
        if (val && list.value) {
          checked1.length = 0;
          list.value.forEach((item: any) => {
            checked1.push(item.pidAndLotId);
            allPrice += item.qty * item.price
          });
          totalPrice.value = allPrice
          // getThis.data.couponPriceShow=allPrice
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          getCouponList()
        } else {
          checked1.length = 0;
          totalPrice.value = 0;
          // getThis.data.couponPriceShow=allPrice;
          getThis.data.isCoupon=false
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          getCouponList()
        }
      };
      const map = new Map();
      const count = ref(0);
      const isApplyOpen = ref();//1:未成单用户
      const isFirst = ref();//1:未成单用户
      const myUserlogin = ref();//是否登录
      const applyTotalOfOrder = ref(0);//订单中已申请数量
      //通过监听商品数量更新优惠券
      watch( count,(newValue, oldValue)=>{
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        getCouponList()
      });
      //单选，val复选框选中的所有id值数据
      const changeBoxGroup = (val: any) => {
        let singPrice = 0
        let syCount = 0;
        let newCount = 0;
        if (val && list.value) {
          list.value.forEach((item: any) => {
            val.forEach((ele: any) => {
              if(item.pidAndLotId == ele) {
                //计算选中的产品总价
                singPrice += item.qty * item.price
                //计算选中的-产品总数和试用产品的数量
                const value = Number(map.get(ele));//产品数量
                if (value) {
                  newCount += value;
                  if(item.cartType && item.cartType == 1) {
                    syCount += value;
                  }
                }
              }
            });
          });
          totalPrice.value = singPrice
        }

        if (list.value && list.value.length && val.length == list.value.length) {
          checked.value = true;
        } else {
          checked.value = false;
          getThis.data.isCoupon=false
          getThis.data.couponId=0;
        }

        //购物车选中的产品的总数
        count.value = newCount;
        //选中的-试用产品的数量
        cartTypeVal1.value = syCount;
      };
      // const map = new Map();
      //  add shop car
      const { addShopCar, addStorge, subStorge } = useShopCar();
      const guid = localStorage.getItem("guid");
      // const count = ref(0);
      function getCount(newValue: any[]) {
        let newCount = 0;
        newValue.forEach((item: any) => {
          const value = Number(map.get(item));
          if (value) {
            newCount += value;
          }
        });
        if (
                list.value &&
                list.value.length &&
                newValue.length == list.value.length
        ) {
          checked.value = true;
        } else {
          checked.value = false;
        }
        count.value = newCount;
        let singPrice = 0
        if (newValue && list.value) {
          list.value.forEach((item: any) => {
            singPrice += item.qty * item.price
          });
          totalPrice.value = singPrice
        }
      }
      //是否使用优惠券
      // function changeCouponUse(){
      //   getThis.data.CouponUse=! getThis.data.CouponUse
      //   if(getThis.data.CouponUse===false){
      //     totalPrice.value+=getThis.data.couponPriceShow
      //     getThis.data.couponPriceShow=0
      //     // getThis.data.couponId=0
      //   }else{
      //     totalPrice.value-=getThis.data.couponPrice
      //     getThis.data.couponPriceShow=getThis.data.couponPrice
      //   }
      // }
      //获取优惠券列表
      function getCouponList() {
        loading.value = true;
        checkFlag.value=true
        axios.post("/M/couponlog/couponShoppingCartList?totalprice=" + totalPrice.value + '&cartGUID=' + guid)
            .then((res) => {
              console.log(res.data);
              getThis.data.couponList=res.data.obj;
              getThis.data.couponPrice=res.data.count;
              getThis.data.couponCount=res.data.data;
              if( getThis.data.couponCount===0|| !getThis.data.couponCount){
                getThis.data.isCoupon=false;
                getThis.data.couponId=0;
              }else {
                getThis.data.CouponUse=true;
                getThis.data.isCoupon=true
              }
              loading.value=false
            })
            .catch((err) => {
              loading.value=false;
              // Toast({
              //   type: "error",
              //   title: err,
              // });
            });
      }
      //获取购物车信息
      function getData(stopRefresh?: boolean) {
        checked1.length = 0;
        axios
                .get("/M/ShoppingCart", {
                  params: {
                    cartGUID: guid,
                  },
                })
                .then((res) => {
                  if (res.data.success) {
                    const data = res.data.obj.list;
                    biaowuPriceOpen.value = res.data.obj.biaowuPriceOpen;
                    freeShippingLimit.value = res.data.obj.freeShippingLimit;
                    isApplyOpen.value = res.data.obj.isApplyOpen;
                    isFirst.value = res.data.obj.isFirst;
                    myUserlogin.value = res.data.obj.myUserlogin;
                    if(res.data.obj.applyTotalOfOrder){
                      applyTotalOfOrder.value = res.data.obj.applyTotalOfOrder;
                    }
                    farePrice.value = res.data.obj.farePrice;
                    let startPrice = 0;
                    data.length>0 && data.forEach((item: any) => {
                      startPrice += item.qty * item.price
                    });
                    totalPrice.value = startPrice

                    list.value = data;
                    if (!stopRefresh) {
                      data.forEach((item: any) => {
                        map.set(item.pidAndLotId, item.qty);
                        checked1.push(item.pidAndLotId);
                      });
                    } else {
                      checked1.length = 0;
                      data.forEach((item: any) => {
                        map.set(item.pidAndLotId, item.qty);
                        checked1.push(item.pidAndLotId);
                      });
                    }
                    getCount(checked1);
                    //更新优惠券
                    getCouponList()
                  } else {
                    Toast({
                      type: "error",
                      title: res.data.msg,
                    });
                  }
                  loading.value = false;
                })
                .catch((err) => {
                  Toast({
                    type: "error",
                    title: err.message,
                  });
                  loading.value = false;
                });
      }
      getData();

      watch(
              () => {
                return checked1;
              },
              (newValue) => {
                getCount(newValue);
              }
      );

      function onAdd(id: string, index: number) {
        isBtnDisabled.value=false;
        setTimeout(function () {
          isBtnDisabled.value=true;
        },1000);
        (list.value as any)[index].qty += 1;
        // count.value += 1;
        map.set(id, (list.value as any)[index].qty);
        getCount(checked1);
        addStorge(id, (list.value as any)[index].qty);

      }
      function onSub(id: string, index: number) {
        isBtnDisabled.value=false;
        setTimeout(function () {
          isBtnDisabled.value=true
        },1000)
        if ((list.value as any)[index].qty <= 1) {
          Toast({
            type: "warning",
            title: "数量不能小于1！",
          });
          return false;
        }
        (list.value as any)[index].qty -= 1;
        // count.value -= 1;
        map.set(id, (list.value as any)[index].qty);
        getCount(checked1);

        addStorge(id, (list.value as any)[index].qty);

        // console.log(getThis.data.CouponUse)
      }
      const loadOption = {
        text: "加载中...",
        color: "#df0024",
        textColor: "#df0024",
      };

      function onDelete() {
        if (!checked1.length) {
          Toast({
            type: "warning",
            title: "请选择要删除的商品！",
          });

          return false;
        }
        Modal({
          title: "提示",
          content: "确定要删除吗？",
          onConfirm: () => {
            const data = qs.stringify({
              ids: checked1.join(","),
            });
            axios
                    .post("/M/Server/DeleteCartItem", data, {
                      params: {
                        cartGUID: guid,
                      },
                    })
                    .then((res) => {
                      if (res.data.success) {
                        totalPrice.value = 0
                        getData(true);
                        getCartInfo()
                      } else {
                        Toast({
                          type: "error",
                          title: res.data.msg,
                        });
                      }
                      loading.value = false;
                    })
                    .catch((err) => {
                      Toast({
                        type: "error",
                        title: err.message,
                      });
                      loading.value = false;
                    });
          },
        });
      }
      function doCheckout(tipInfo: string, checked1: any) {
        if (!count.value) {
          Toast({
            type: "warning",
            title: "请选择要结算的商品！",
          });
          return false;
        }
        axios
                .post(
                        "/M/Server/PreCheckout",
                        qs.stringify({
                          idstr: checked1.join(","),
                        }),
                        {
                          params: {
                            validate: true,
                          },
                        }
                )
                .then((res) => {
                  if (res.data.success) {
                    router.push({
                      name: "SubmitOrder",
                      query: {
                        type: 0,
                      },
                    });
                    // Modal({
                    //   optionControl:2,
                    //   title: "提示",
                    //   content: tipInfo,
                    //   // closeText: "关闭",
                    //   onClose: () => {
                    //     router.push({
                    //       name: "SubmitOrder",
                    //       query: {
                    //         type: 0,
                    //       },
                    //     });
                    //   },
                    // });
                  } else {
                    Toast({
                      type: "error",
                      title: res.data.msg,
                    });
                  }
                })
                .catch((err) => {
                  Toast({
                    type: "error",
                    title: err.message,
                  });
                })
                .catch((err) => {
                  Toast({
                    type: "error",
                    title: err.message,
                  });
                });
      }
      function onSubmit() {
        let tipInfo = '提交成功';
        if(getThis.data.couponCount){
          tipInfo = '提交成功！您有'+ getThis.data.couponCount+'张优惠券可用，最多可优惠'+ getThis.data.couponPrice+'元，请联系客服使用。'
          if (!userInfo.realName || !userInfo.userUnit) {
            tipInfo = '提交成功！您有'+ getThis.data.couponCount+'张优惠券可用，最多可优惠'+ getThis.data.couponPrice+'元，请联系客服使用。'
          }
        }

        if (!count.value) {
          Toast({
            type: "warning",
            title: "请选择要结算的商品！",


          });
          return false;
        }
        // 若没有勾选优惠券
        if(!getThis.data.CouponUse){
          getThis.data.couponId=0
        }
        //新用户提示
        // if(isFirst.value && isFirst.value ==1){
        //     Modal({
        //       title: "提示",
        //       content: "检测您为新用户，审核通过即可享受首单半价，如有疑问请联系客服人员！",
        //       onConfirm: () => {
        //         console.log("haode")
        //         doCheckout(tipInfo,checked1);
        //       },
        //     });
        // }else{
        //   doCheckout(tipInfo,checked1);
        // }
        doCheckout(tipInfo,checked1);
      }
      function changeEdit(id: string, item: any, index: any) {
        if (item<=0) {
          Toast({
            type: "error",
            title: '数量不能小于1',
            onClose: () => {
              location.reload();
            },
          });
          return false
        }
        addStorge(id, item);
        //更新优惠券
        getCouponList()
      }
      // function onClosePackage() {
      //   showRedPackage.value = false
      //   let tipInfo = '下单成功！客服人员将联系您确认订单'
      //   if (!userInfo.realName || !userInfo.userUnit) {
      //     tipInfo = '下单成功！客服人员将联系您确认订单，完善资料联系更方便。'
      //   }
      //   Modal({
      //     title: "提示",
      //     content: tipInfo,
      //     closeText: "关闭",
      //     onClose: () => {
      //       getCartInfo()
      //       router.push({
      //         name: "Order",
      //         query: {
      //           type: 0,
      //         },
      //       });
      //     },
      //   });
      // }
      //
      // const codeImg = ref("/M/Server/GetVerifyCodeImg");
      // function getCodeImg() {
      //   codeImg.value = "/M/Server/GetVerifyCodeImg?random=" + Math.random();
      // }
      return {
        list,
        checked1,
        cartTypeVal1,
        checked,
        changeBox,
        changeBoxGroup,
        onAdd,
        onSub,
        count,
        loadOption,
        loading,
        onDelete,
        onSubmit,
        totalPrice,
        freeShippingLimit,
        isApplyOpen,
        isFirst,
        myUserlogin,
        applyTotalOfOrder,
        farePrice,
        userInfo,
        delivery,
        biaowuPriceOpen,
        changeEdit,
        // showRedPackage,
        passCode,
        visiblePic,
        confirm,
        getData,
        getCouponList,
        isBtnDisabled,
        checkFlag,
        doCheckout

      };
    },
  });
</script>

<style lang="scss" scoped>

.modal-close {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 0;
  top: 0;
  border-radius: 50%;
  border: 0;
}

#couponGiveForm > label input, #couponGiveForm > label span {
  position: relative;
  margin: 0 0.2rem;
  /*bottom: 5px;*/
}

#couponGiveForm > label {
  display: flex;
  padding: 0.26667rem 0;
  border-bottom: 0.02667rem solid #eee;
  overflow: hidden;
  justify-content: space-between;
}

.modal-backdrop {
  /* 当打开模态框时，用半透明的蒙层遮挡网页剩余内容 */
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);

}

//#couponGiveForm > label img {
//  width: 40px;
//  height: 40px;
//  border-radius: 50%;
//}

.modal-rushMessage {
  font-size: 16px;
  text-align: center;
  color: white;
  height: 130px;
  padding-top: 25px;
  margin: 0 auto;
  width: 80%;
}

.coupon-button {
  display: flex;
  width: 50%;
  margin: 0 auto;
  justify-content: space-around;
}

#couponGiveForm {
  height: 280px;
  overflow: auto;
}

.coupon_btn {
  color: #fff;
  font-size: 14px;
  margin-top: 10px;
  background: #e00025;
  padding: 4px 15px;
}

.modal-body {
  background: white;
  border-radius: 8px;
  z-index: 99;
  width: 320px;
  height: 340px;
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 10px;
  font-size: 14px;
}

.modal-body ul {
  text-align: left;
  font-size: 12px;
  height: 150px;
  overflow-y: auto;
}

.modal-body ul li {
  margin: 8px auto;
  overflow: hidden;
  white-space: nowrap;
  width: 85%;
  text-overflow: ellipsis;
}

.modal-body ul li span {
  color: red;
}
  main {
    padding-bottom: 2.933rem;
  }
  .CouponUse{
    display: inline-block;
    border: 1px solid #eee;
    width: 20px;
    position: relative;
    top: 3px;
    margin-right: 5px;
    height: 20px;
  }

  .active{
    display: inline-block;
    background-size: contain;
    background-image: url("https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/aacf1f5563a79ecfe79c7b585f183cab.png");
    width: 20px;
    position: relative;
    top: 3px;
    margin-right: 5px;
    height: 20px;
    border: none;
    background-color:white;
  }
  .CouponInfo{
    color: #d64b4b;
  }
  .CouponCount{
    float: right;
    font-size: 16px;
    margin-right: 1.1rem;
    color: #d64b4b;
    padding: 0.1rem 0.26667rem;
  }
  .changeCoupon{
    margin-left: 35px;
    border: 1px solid #eee;
    padding: 2px 10px;
  }
  .car-li {
    position: relative;
    font-size: 14px;
    width: 100%;
    height: auto;
    background-color: #fff;
    border-top: 1px solid #eee;
    overflow: hidden;
    padding: 0.6rem 0.267rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
  }

  .car-li .checkbox {
    position: absolute;
    width: 0.533rem;
    height: 0.533rem;
    left: 0.267rem;
    top: 50%;
    margin-top: -0.267rem;
    z-index: 10;
  }
  .car-li .checkbox .car-check {
    position: absolute;

    width: 0.533rem;
    height: 0.533rem;
    left: 0;
    top: 0;
    z-index: 10;
    opacity: 0;
  }
  .car-li .checkbox img {
    position: absolute;
    width: 0.533rem;
    height: 0.533rem;
    vertical-align: top;
    left: 0;
    top: 0;
    z-index: 5;
    display: none;
  }
  .car-li .checkbox::after {
    content: "";
    position: absolute;
    width: 0.533rem;
    height: 0.533rem;
    left: 0;
    top: 0;
    border: 1px solid #ccc;
    border-radius: 0.067rem;
    box-sizing: border-box;
  }
  .product-wapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .product-pic {
    margin: 0 15px;
    width: 2rem;
    height: 2rem;
    a {
      display: block;
    }
    img {
      // width: 2rem;
      // height: 2rem;
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .product-info {
    padding-right: 0.267rem;
    img {
      width: 16px;
      vertical-align: middle;
    }
    .product-no {
      // font-size: 0.347rem;
      font-size: 12px;
      color: #999;
      width: 16em;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .product-name {
      width: 16em;
      display: block;
      font-size: 0.373rem;
      color: #666;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      padding-right: 15px;
      position: relative;
      top: -1px;
    }
    .poroduct-num {
      // margin-top: 0.133rem;
      line-height: 23px;
    }
    .input {
      display: inline-block;
      border: 1px solid #eee;
      // height: 0.667rem;
      height: 23px;
      vertical-align: middle;
      box-sizing: border-box;
    }
    input {
      width: 1.1rem;
      height: 100%;
      outline: none;
      border: none;
      vertical-align: top;
      box-sizing: border-box;
      color: #666;
      text-align: center;
      font-size: 13px;
    }
    input:disabled {
      background-color: transparent;
    }
    .sub {
      display: inline-block;
      position: relative;
      // left: 0.213rem;
      width: 0.667rem;
      // height: 0.667rem;
      height: 23px;
      text-align: center;
      // line-height: 0.667rem;
      vertical-align: middle;
      // background: #f4f4f4 url("https://www.bzwz.com/static/m/images/d13.png")
      //   no-repeat center center;
      background: #f4f4f4 url("../assets/img/shopCar/d13.png")
      no-repeat center center;
    }
    .add {
      display: inline-block;
      position: relative;
      // left: -0.213rem;
      width: 0.667rem;
      // height: 0.667rem;
      height: 23px;
      text-align: center;
      // line-height: 0.667rem;
      vertical-align: middle;
      // background: #f4f4f4 url("https://www.bzwz.com/static/m/images/d14.png")
      //   no-repeat center center;
      background: #f4f4f4 url("../assets/img/shopCar/d14.png")
      no-repeat center center;
      background-size: 0.267rem 0.267rem;
    }
  }

  .dashboard {
    position: fixed;
    bottom: 1.333rem;
    left: 0;
    right: 0;
    background-color: #fff;
    height: 1.333rem;
    text-align: center;
    font-size: 0.373rem;
    border-top: 0.027rem solid #f4f4f4;
    line-height: 1.333rem;
    color: #666;
    z-index: 1;
  }

  .dashboard .dashboard-checkbox {
    position: absolute;
    top: 50%;
    margin-top: -0.267rem;
    padding-left: 0.267rem;
    line-height: 0.533rem;
    height: 0.533rem;
  }
  .dashboard .dashboard-checkbox input {
    width: 0.533rem;
    height: 0.533rem;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 10;
    position: absolute;
  }
  .dashboard .dashboard-checkbox img {
    position: absolute;
    width: 0.533rem;
    height: 0.533rem;
    vertical-align: top;
    left: 0;
    top: 0;
    z-index: 5;
    display: none;
  }

  .del {
    display: inline-block;
    color: #666;
    margin-left: 0.667rem;
  }
  .pay {
    position: absolute;
    right: 0.267rem;
    top: 0;
    // width: 2.667rem;
    padding: 0 8px;
    height: 0.933rem;
    background-color: #d64b4b;
    color: #fff;
    border-radius: 0.933rem;
    text-align: center;
    line-height: 0.933rem;
    top: 50%;
    margin-top: -0.467rem;
  }

  .total-price {
    // position: relative;
    // left: 28px;
    position: absolute;
    width: 110px;
    right: 110px;
    span {
      color: #d64b4b;
    }
  }

  .extra-price {
    // position: relative;
    // left: 28px;
    // height: 100%;
    position: absolute;
    width: 110px;
    right: 110px;
    line-height: 27px;
    div {
      height: 20px;
    }
    .price {
      font-size: 12px;
      span {
        color: #d64b4b;
        font-size: 14px;
      }
    }
    .extra {
      font-size: 12px;
      color: #d64b4b;
    }
  }

  .single-price {
    font-size: 16px;
    color: #666;
    margin-left: 20px;
    color: #d64b4b;
  }

  .toast-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.75);
  }

  .modal {
    position: fixed;
    z-index: 101;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 5px;
    color: #333;
    padding: 10px 20px;
    min-height: 50px;
    max-width: 340px;
    width: 80%;
    font-size: 14px;
    box-sizing: border-box;
    .modal-header {
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      text-align: center;
    }
    .modal-content {
      padding: 15px 0;
      line-height: 24px;
      text-align: center;
    }
    .modal-footer {
      display: flex;
      line-height: 40px;
      justify-content: space-between;
      div {
        //flex: 1;
        text-align: center;
        width: 45%;
        background-color: #ccc;
        border-radius: 5px;
      }
      .confirm {
        color: #fff;
        background-color: #d64b4b;
        border-radius: 5px;
      }
    }
    img {
      width: 30px;
      height: 30px;

      margin: 0 auto;
      margin-bottom: 10px;
      display: block;
    }
  }
  .modal-input input {
    border: none;
    line-height: 44px;
    width: 100%;
    display: block;
    margin: 5px;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding-left: 5px;
    box-sizing: border-box;
  }

  .code-item {
    position: relative;
    padding-right: 3.467rem;
    box-sizing: border-box;
  }
  .code-pic {
    position: relative;
    padding-right: 3.467rem;
    box-sizing: border-box;
  }

  .code-pic img {
    position: absolute;
    right: 0;
    top: 0.22rem;
    width: 2.4rem;
    height: 0.8rem;
    border-radius: 0.067rem;
    // border: solid 0.027rem #d64b4b;
    line-height: 0.8rem;
    text-align: center;
    color: #d64b4b;
    font-size: 0.373rem;
    background: none;
    outline: none;
    display: block;
  }
  .form-label {
    width: 100%;
    height: 1.2rem;
    line-height: 1.2rem;
    background-color: #f4f4f4;
    border-radius: 0.067rem;
    padding-left: 0.4rem;
    box-sizing: border-box;
    margin-bottom: 0.587rem;
  }
  .form-label input {
    outline: none;
    border: none;
    width: 100%;
    height: 1.2rem;
    line-height: 1.2rem;
    vertical-align: top;
    background-color: transparent;
  }
</style>
