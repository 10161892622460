
import { computed, defineComponent, reactive, ref, watch, inject,getCurrentInstance } from "vue";
  import UiCheckboxGroup from "@/components/UI/CheckboxGroup";
  import UiCheckbox from "@/components/UI/Checkbox";

  import axios from "@/api/axios";
  import api from "@/api/axios";
  import Toast from "@/components/UI/Toast";
  import useShopCar from "@/hooks/useShopCar";
  import Loading from "@/components/UI/Loading/src/Loading.vue";
  import qs from "qs";
  import Modal from "@/components/UI/Modal";
  import router from "@/router";
  import Empty from "@/components/common/Empty.vue";
  import { getCartInfo } from "@/api/useChangeShopCar";
  import OrderRedPackage from "@/components/common/OrderRedPackage.vue"
  export default defineComponent({
    name: "ShopCar",
    components: {
      UiCheckbox,
      UiCheckboxGroup,
      Loading,
      Empty,
      // OrderRedPackage
    },
    data() {
      return {
        CouponUse:true,
        showModal: false,
        // 优惠券
        coupon:'',
        //展示金额
        couponPriceShow:0,
        //优惠券个数
        couponCount:0,
        //真实金额
        couponPrice:0,
        couponList:[],
        isCoupon:false,
        couponId:0,
        // couponFreeMailSize:0,
      }
    },
    methods:{
      getModal(){
        this.showModal=true
      },
      closeModal(){
        this.showModal=false
        // this.couponId= this.couponList[0].id
      },
      //选优惠券
      getCouponId(id: any){
        this.couponId=id
      },
      //确定选中 并更新外部优惠券/总价信息
      changeCoupon(){
        // let indexCoupon=0
        // this.couponList.map((item: any,index: any)=>{
        //   if(item.id===this.couponId){
        //     indexCoupon=index
        //   }
        // });
        // this.totalPrice+=this.couponPrice
        // this.couponPrice=this.couponList[indexCoupon].amount
        // this.couponPriceShow=this.couponList[indexCoupon].amount
        // this.totalPrice-=this.couponPrice
        // console.log(this.totalPrice)
        // Toast({
        //   type: "success",
        //   title: '更换成功',
        // });
        this.showModal=false
      }
    },
    setup() {
      const checkFlag = ref(true);
      const visiblePic = ref(false)
      const passCode = ref<string>("");
      // const showRedPackage = ref(false)
      const biaowuPriceOpen = ref()
      const delivery = ref(1)
      const list = ref<[] | null>(null);
      const checked = ref(true);
      const checked1 = reactive<any>([]);
      const cartTypeVal1 = ref(0);
      const loading = ref(true);
      const totalPrice = ref(0)
      //包邮限制金额
      const freeShippingLimit = ref(0)
      const farePrice = ref(0)
      const userInfo = inject("userInfo") as any;
      //获取vue data
      const getThis = getCurrentInstance() as any;
      const isBtnDisabled=ref(true);
      //观察选择
      const changeBox = (val: any) => {
        let allPrice = 0
        if (val && list.value) {
          checked1.length = 0;
          list.value.forEach((item: any) => {
            checked1.push(item.pidAndLotId);
            allPrice += item.qty * item.price
          });
          totalPrice.value = allPrice
          // getThis.data.couponPriceShow=allPrice
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          getCouponList()
        } else {
          checked1.length = 0;
          totalPrice.value = 0;
          // getThis.data.couponPriceShow=allPrice;
          getThis.data.isCoupon=false
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          getCouponList()
        }
      };
      const map = new Map();
      const count = ref(0);
      const isApplyOpen = ref();//1:未成单用户
      const isFirst = ref();//1:未成单用户
      const myUserlogin = ref();//是否登录
      const applyTotalOfOrder = ref(0);//订单中已申请数量
      //通过监听商品数量更新优惠券
      watch( count,(newValue, oldValue)=>{
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        getCouponList()
      });
      //单选，val复选框选中的所有id值数据
      const changeBoxGroup = (val: any) => {
        let singPrice = 0
        let syCount = 0;
        let newCount = 0;
        if (val && list.value) {
          list.value.forEach((item: any) => {
            val.forEach((ele: any) => {
              if(item.pidAndLotId == ele) {
                //计算选中的产品总价
                singPrice += item.qty * item.price
                //计算选中的-产品总数和试用产品的数量
                const value = Number(map.get(ele));//产品数量
                if (value) {
                  newCount += value;
                  if(item.cartType && item.cartType == 1) {
                    syCount += value;
                  }
                }
              }
            });
          });
          totalPrice.value = singPrice
        }

        if (list.value && list.value.length && val.length == list.value.length) {
          checked.value = true;
        } else {
          checked.value = false;
          getThis.data.isCoupon=false
          getThis.data.couponId=0;
        }

        //购物车选中的产品的总数
        count.value = newCount;
        //选中的-试用产品的数量
        cartTypeVal1.value = syCount;
      };
      // const map = new Map();
      //  add shop car
      const { addShopCar, addStorge, subStorge } = useShopCar();
      const guid = localStorage.getItem("guid");
      // const count = ref(0);
      function getCount(newValue: any[]) {
        let newCount = 0;
        newValue.forEach((item: any) => {
          const value = Number(map.get(item));
          if (value) {
            newCount += value;
          }
        });
        if (
                list.value &&
                list.value.length &&
                newValue.length == list.value.length
        ) {
          checked.value = true;
        } else {
          checked.value = false;
        }
        count.value = newCount;
        let singPrice = 0
        if (newValue && list.value) {
          list.value.forEach((item: any) => {
            singPrice += item.qty * item.price
          });
          totalPrice.value = singPrice
        }
      }
      //是否使用优惠券
      // function changeCouponUse(){
      //   getThis.data.CouponUse=! getThis.data.CouponUse
      //   if(getThis.data.CouponUse===false){
      //     totalPrice.value+=getThis.data.couponPriceShow
      //     getThis.data.couponPriceShow=0
      //     // getThis.data.couponId=0
      //   }else{
      //     totalPrice.value-=getThis.data.couponPrice
      //     getThis.data.couponPriceShow=getThis.data.couponPrice
      //   }
      // }
      //获取优惠券列表
      function getCouponList() {
        loading.value = true;
        checkFlag.value=true
        axios.post("/M/couponlog/couponShoppingCartList?totalprice=" + totalPrice.value + '&cartGUID=' + guid)
            .then((res) => {
              console.log(res.data);
              getThis.data.couponList=res.data.obj;
              getThis.data.couponPrice=res.data.count;
              getThis.data.couponCount=res.data.data;
              if( getThis.data.couponCount===0|| !getThis.data.couponCount){
                getThis.data.isCoupon=false;
                getThis.data.couponId=0;
              }else {
                getThis.data.CouponUse=true;
                getThis.data.isCoupon=true
              }
              loading.value=false
            })
            .catch((err) => {
              loading.value=false;
              // Toast({
              //   type: "error",
              //   title: err,
              // });
            });
      }
      //获取购物车信息
      function getData(stopRefresh?: boolean) {
        checked1.length = 0;
        axios
                .get("/M/ShoppingCart", {
                  params: {
                    cartGUID: guid,
                  },
                })
                .then((res) => {
                  if (res.data.success) {
                    const data = res.data.obj.list;
                    biaowuPriceOpen.value = res.data.obj.biaowuPriceOpen;
                    freeShippingLimit.value = res.data.obj.freeShippingLimit;
                    isApplyOpen.value = res.data.obj.isApplyOpen;
                    isFirst.value = res.data.obj.isFirst;
                    myUserlogin.value = res.data.obj.myUserlogin;
                    if(res.data.obj.applyTotalOfOrder){
                      applyTotalOfOrder.value = res.data.obj.applyTotalOfOrder;
                    }
                    farePrice.value = res.data.obj.farePrice;
                    let startPrice = 0;
                    data.length>0 && data.forEach((item: any) => {
                      startPrice += item.qty * item.price
                    });
                    totalPrice.value = startPrice

                    list.value = data;
                    if (!stopRefresh) {
                      data.forEach((item: any) => {
                        map.set(item.pidAndLotId, item.qty);
                        checked1.push(item.pidAndLotId);
                      });
                    } else {
                      checked1.length = 0;
                      data.forEach((item: any) => {
                        map.set(item.pidAndLotId, item.qty);
                        checked1.push(item.pidAndLotId);
                      });
                    }
                    getCount(checked1);
                    //更新优惠券
                    getCouponList()
                  } else {
                    Toast({
                      type: "error",
                      title: res.data.msg,
                    });
                  }
                  loading.value = false;
                })
                .catch((err) => {
                  Toast({
                    type: "error",
                    title: err.message,
                  });
                  loading.value = false;
                });
      }
      getData();

      watch(
              () => {
                return checked1;
              },
              (newValue) => {
                getCount(newValue);
              }
      );

      function onAdd(id: string, index: number) {
        isBtnDisabled.value=false;
        setTimeout(function () {
          isBtnDisabled.value=true;
        },1000);
        (list.value as any)[index].qty += 1;
        // count.value += 1;
        map.set(id, (list.value as any)[index].qty);
        getCount(checked1);
        addStorge(id, (list.value as any)[index].qty);

      }
      function onSub(id: string, index: number) {
        isBtnDisabled.value=false;
        setTimeout(function () {
          isBtnDisabled.value=true
        },1000)
        if ((list.value as any)[index].qty <= 1) {
          Toast({
            type: "warning",
            title: "数量不能小于1！",
          });
          return false;
        }
        (list.value as any)[index].qty -= 1;
        // count.value -= 1;
        map.set(id, (list.value as any)[index].qty);
        getCount(checked1);

        addStorge(id, (list.value as any)[index].qty);

        // console.log(getThis.data.CouponUse)
      }
      const loadOption = {
        text: "加载中...",
        color: "#df0024",
        textColor: "#df0024",
      };

      function onDelete() {
        if (!checked1.length) {
          Toast({
            type: "warning",
            title: "请选择要删除的商品！",
          });

          return false;
        }
        Modal({
          title: "提示",
          content: "确定要删除吗？",
          onConfirm: () => {
            const data = qs.stringify({
              ids: checked1.join(","),
            });
            axios
                    .post("/M/Server/DeleteCartItem", data, {
                      params: {
                        cartGUID: guid,
                      },
                    })
                    .then((res) => {
                      if (res.data.success) {
                        totalPrice.value = 0
                        getData(true);
                        getCartInfo()
                      } else {
                        Toast({
                          type: "error",
                          title: res.data.msg,
                        });
                      }
                      loading.value = false;
                    })
                    .catch((err) => {
                      Toast({
                        type: "error",
                        title: err.message,
                      });
                      loading.value = false;
                    });
          },
        });
      }
      function doCheckout(tipInfo: string, checked1: any) {
        if (!count.value) {
          Toast({
            type: "warning",
            title: "请选择要结算的商品！",
          });
          return false;
        }
        axios
                .post(
                        "/M/Server/PreCheckout",
                        qs.stringify({
                          idstr: checked1.join(","),
                        }),
                        {
                          params: {
                            validate: true,
                          },
                        }
                )
                .then((res) => {
                  if (res.data.success) {
                    router.push({
                      name: "SubmitOrder",
                      query: {
                        type: 0,
                      },
                    });
                    // Modal({
                    //   optionControl:2,
                    //   title: "提示",
                    //   content: tipInfo,
                    //   // closeText: "关闭",
                    //   onClose: () => {
                    //     router.push({
                    //       name: "SubmitOrder",
                    //       query: {
                    //         type: 0,
                    //       },
                    //     });
                    //   },
                    // });
                  } else {
                    Toast({
                      type: "error",
                      title: res.data.msg,
                    });
                  }
                })
                .catch((err) => {
                  Toast({
                    type: "error",
                    title: err.message,
                  });
                })
                .catch((err) => {
                  Toast({
                    type: "error",
                    title: err.message,
                  });
                });
      }
      function onSubmit() {
        let tipInfo = '提交成功';
        if(getThis.data.couponCount){
          tipInfo = '提交成功！您有'+ getThis.data.couponCount+'张优惠券可用，最多可优惠'+ getThis.data.couponPrice+'元，请联系客服使用。'
          if (!userInfo.realName || !userInfo.userUnit) {
            tipInfo = '提交成功！您有'+ getThis.data.couponCount+'张优惠券可用，最多可优惠'+ getThis.data.couponPrice+'元，请联系客服使用。'
          }
        }

        if (!count.value) {
          Toast({
            type: "warning",
            title: "请选择要结算的商品！",


          });
          return false;
        }
        // 若没有勾选优惠券
        if(!getThis.data.CouponUse){
          getThis.data.couponId=0
        }
        //新用户提示
        // if(isFirst.value && isFirst.value ==1){
        //     Modal({
        //       title: "提示",
        //       content: "检测您为新用户，审核通过即可享受首单半价，如有疑问请联系客服人员！",
        //       onConfirm: () => {
        //         console.log("haode")
        //         doCheckout(tipInfo,checked1);
        //       },
        //     });
        // }else{
        //   doCheckout(tipInfo,checked1);
        // }
        doCheckout(tipInfo,checked1);
      }
      function changeEdit(id: string, item: any, index: any) {
        if (item<=0) {
          Toast({
            type: "error",
            title: '数量不能小于1',
            onClose: () => {
              location.reload();
            },
          });
          return false
        }
        addStorge(id, item);
        //更新优惠券
        getCouponList()
      }
      // function onClosePackage() {
      //   showRedPackage.value = false
      //   let tipInfo = '下单成功！客服人员将联系您确认订单'
      //   if (!userInfo.realName || !userInfo.userUnit) {
      //     tipInfo = '下单成功！客服人员将联系您确认订单，完善资料联系更方便。'
      //   }
      //   Modal({
      //     title: "提示",
      //     content: tipInfo,
      //     closeText: "关闭",
      //     onClose: () => {
      //       getCartInfo()
      //       router.push({
      //         name: "Order",
      //         query: {
      //           type: 0,
      //         },
      //       });
      //     },
      //   });
      // }
      //
      // const codeImg = ref("/M/Server/GetVerifyCodeImg");
      // function getCodeImg() {
      //   codeImg.value = "/M/Server/GetVerifyCodeImg?random=" + Math.random();
      // }
      return {
        list,
        checked1,
        cartTypeVal1,
        checked,
        changeBox,
        changeBoxGroup,
        onAdd,
        onSub,
        count,
        loadOption,
        loading,
        onDelete,
        onSubmit,
        totalPrice,
        freeShippingLimit,
        isApplyOpen,
        isFirst,
        myUserlogin,
        applyTotalOfOrder,
        farePrice,
        userInfo,
        delivery,
        biaowuPriceOpen,
        changeEdit,
        // showRedPackage,
        passCode,
        visiblePic,
        confirm,
        getData,
        getCouponList,
        isBtnDisabled,
        checkFlag,
        doCheckout

      };
    },
  });
